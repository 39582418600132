<template>
  <div class="header">
    <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </div>
    <div id="banner">
      <img id="logo" alt="Abdera7mane's logo" src="./assets/logo.svg">
    </div>
    <div id="spacing"/>
  </div>
  
  <div class="content">
    <router-view/>
  </div>
  
  <div class="footer">

  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #393e46;
}

#app a {
  text-decoration: none;
  color: #f96d00;
  font-weight: bold;
}

#nav {
  background-color:#222831;
}

#nav a {
  display: inline-block;
  padding: 20px 15px;
  color: white;
  text-transform: uppercase;
  transition-duration: 0.5s;
}

#nav a:hover {
  color: black;
  background: #f2f2f2;
}

#nav a.router-link-exact-active:hover {
  color: #f2f2f2;
  background: #f96d00;
}

#nav a.router-link-exact-active {
  color: #f96d00;
  background: #f2f2f2;
}

.content {
  min-height: 500px;
}

.footer {
  min-height: 60px;
  background-color:#222831;
}

#spacing {
  height: 50px;
  width: 100%;
}

#logo {
  height: 200px;
  width: 200px;
}

#banner {
  height: 200px;
  width: 100%;
  background-color: black;
  background-image: url('./assets/banner.svg');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
