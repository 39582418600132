<template>
    <ul>
        <Pop 
            title="GodoCord" 
            icon="godocord.svg"
            description="A library written in GDScript language to interact with Discord public API and their client in Godot Engine 3.1+."
            link="https://github.com/Abdera7mane/GodoCord"
        />
        <Pop
            title="ClanZ"
            icon="clanz.svg"
            description="Spigot plugin that adds clans and teaming functionalities made with performance and scalability in mind."
            link="https://github.com/Abdera7mane/ClanZ"
        />
        <Pop 
            title="next?" 
            icon="upcoming_project.svg"
            description="reserved for future projects."
        />
    </ul>
</template>

<script>
import Pop from '@/components/models/Pop.vue'

export default {
    name: 'Main',
    components: {
        Pop
    }
}
</script>

<style scoped>

</style>