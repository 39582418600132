<template>
    <div class="pop">
        <img id="icon" :src="require(`@/assets/${icon}`)"/>
        <h3 id="title">{{ title }}</h3>
        <div id="content">
            <p>{{ description }}</p>
        </div>
        <div id="footer">
            <a v-if="link" :href="link">more ➥</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pop',
    props: {
        description: String,
        title: {
            default: 'no title',
            type: String
        },
        link: String,
        icon: String
    }
}
</script>

<style scoped>
.pop {
    height: 200px;
    width: 360px;
    margin: 30px;
    background-color: whitesmoke;
    display: inline-table;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 10%);
    position: relative;
    top: 0;
    transition: top 0.1s ease-in-out;
    vertical-align: top;
}

.pop:hover {
    top: -10px;
}

.pop * {
    text-align: left;
}

.pop p {
    vertical-align: middle;
}

#icon {
    margin: 10px;
    height: 80px;
    width: 80px;
    float: left;
    background-repeat: no-repeat;
}

#title {
    margin-top: 25px;
    text-transform: capitalize;
}

#content {
    margin: 30px;
    margin-left: 70px;
    min-height: 30px;
    word-spacing: 2px;
    line-height: 20px;
}

#footer {
    margin-top: auto;
    position: relative;                 
    bottom: 0;
    background-repeat: no-repeat;
    background-image: url('../../assets/pop_footer.svg');
    background-color: transparent;
    background-clip: border-box;
    border-radius: inherit;
    min-height: 82px;
    width: 100%;
}

#footer a {
    position: absolute;                 
    bottom: 10px;
    right: 20px;
    text-align: right;
}

</style>