<template>
  <div class="home">
    <h1>Welcome to my profile 👋</h1>
    <div id="spacing"/>
    <p>
      Here I post my latest projects and recent work<br/>
      and all about what I find interesting and worth sharing on this wall.
    </p>
    <div id="spacing"/>
    <Main/>
  </div>
</template>
<script>
// @ is an alias to /src
import Main from '@/components/Main.vue'

export default {
  name: 'Home',
  components: {
    Main
  }
}
</script>